@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

:root {
  --primary: #f35151;
  --secondary: #6d15df;
}
h1,h2,h3 {
  margin: 0.2;
}

body {
  margin: 0.3;
  color: #333;
  font-family: 'Poppins';
  background: #f2f2f2;
}
nav {
  background: var(--primary);
  padding: 10px 20px;
  text-align: center;
}
nav a {
  color: #f2f2f2;
  margin: 10px;
  display: inline-block;
}
nav h1 {
  color: #fff;
}
.page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}